// src/App.js

import React, { Component } from 'react';

import RightInsole from './gray_R.svg';
import LeftInsole from './gray_L.svg';

import { VictoryChart, VictoryLine, VictoryZoomContainer, VictoryBrushContainer, createContainer, VictoryCursorContainer } from 'victory';

import { Grid, Header, List, Segment, Input } from 'semantic-ui-react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Connect, withAuthenticator } from 'aws-amplify-react';

import * as queries from './graphql/queries';

import aws_exports from './aws-exports';
Amplify.configure(aws_exports);




function makeComparator(key, order = 'asc') {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === 'desc' ? (comparison * -1) : comparison;
  };
}




class UserList extends React.Component {
  userItems() {
    return this.props.users.sort(makeComparator('email')).map(user =>
      <List.Item key={user.sub}>
        <NavLink to={`/a/${user.sub}`}>{user.email}</NavLink>
      </List.Item>
    );
  }

  render() {
    return (
      <Segment>
        <Header as='h3'>My Users</Header>
        <List divided relaxed>
          {this.userItems()}
        </List>
      </Segment>

    );
  }
}

class SessionList extends React.Component {
  // sessionItems() {
  //   return this.props.sessions.sort(makeComparator('timeStamp', 'desc')).map(session =>
  //     // <List.Item key={session.timeStamp}>
  //     //   <NavLink to={`/sessions/${session.timeStamp}`}>{session.timeStamp}    {session.exerciseType}     {session.sessionLength}</NavLink>
  //     // </List.Item>


  //   <List.Item key={session.timeStamp}>
  //     <List.Content floated='right'>
  //       {this.getSessionMetrics(session)}    
  //     </List.Content>
  //     <List.Content><NavLink to={`/sessions/${session.timeStamp}`}>{this.getDate(session.timeStamp)}</NavLink></List.Content>

  //   </List.Item>
  //   );
  // }

  SessionSegments() {
    console.log("session segs, user id:", this.props.user);
    return this.props.sessions.sort(makeComparator('timeStamp', 'desc')).map(session =>
      <List.Item key={session.timeStamp}>
        <Segment.Group>
          <Segment><NavLink to={`/new/${this.props.user}/${session.timeStamp}`}>{this.getDate(session.timeStamp)}</NavLink></Segment>
          <Segment.Group horizontal>
            <Segment inverted color="blue">{session.exerciseType}</Segment>
            <Segment inverted color="blue">{this.getMins(session.sessionLength)}</Segment>
            <Segment inverted color="blue">{this.getSessionMetrics(session)}</Segment>
          </Segment.Group>
        </Segment.Group>
      </List.Item>
    );
  }

  getDate(ts) {
    var d = new Date(Number(ts));
    return d.toDateString();
  }
  getMins(t) {
    return String(Math.round(t / (1000 * 60))) + " minutes";
  }
  getSessionMetrics(m) {
    const type = m.exerciseType;
    var stats = "";
    if (type === "Balance") {
      stats = "Grid Completions: " + m.gridRounds;
    } else if (type === "Walking") {
      stats = "Steps: " + m.steps;
    }
    return stats;
  }

  square = { width: 120, height: 120 };


  render() {
    return (
      <div>
        <Segment circular style={this.square} color='purple'>
          <Header as='h4'>
            Streak
        <Header.Subheader>2 days</Header.Subheader>
          </Header>
        </Segment>
        <Segment circular style={this.square} color='pink'>
          <Header as='h5' >
            Frequency
        <Header.Subheader>4 days/wk</Header.Subheader>
          </Header>
        </Segment>
        <Segment circular style={this.square} color='yellow'>
          <Header as='h4' >
            Avg Length
        <Header.Subheader>35 mins</Header.Subheader>
          </Header>
        </Segment>


        <Segment color='grey'>
          <Header as='h3'>Sessions</Header>
          {this.SessionSegments()}
        </Segment>



      </div>
    );
  }

  // <List divided relaxed>
  //           {this.sessionItems()}
  //         </List>
}



class SessionsListLoader extends React.Component {

  render() {
    return (
      <Connect
        query={graphqlOperation(queries.getUserSessions, { id: this.props.id })}
      >
        {({ data, loading }) => {
          if (loading) { return <div>Loading...</div>; }
          //if (!data.getMyUsers) return;
          console.log("session list loader, user id:", this.props.id);
          return <SessionList sessions={data.getUserSessions} user={this.props.id} />;
        }}
      </Connect>
    );
  }
}





class UsersListLoader extends React.Component {
  // onNewAlbum = (prevQuery, newData) => {
  //     // When we get data about a new album, we need to put in into an object 
  //     // with the same shape as the original query results, but with the new data added as well
  //     let updatedQuery = Object.assign({}, prevQuery);
  //     updatedQuery.listAlbums.items = prevQuery.listAlbums.items.concat([newData.onCreateAlbum]);
  //     return updatedQuery;
  // }

  render() {
    return (
      <Connect
        query={graphqlOperation(queries.getMyUsers)}
      >
        {({ data, loading }) => {
          if (loading) { return <div>Loading...</div>; }
          //if (!data.getMyUsers) return;
          console.log(data.getMyUsers);
          return <UserList users={data.getMyUsers} />;
        }}
      </Connect>
    );
  }
}


class SessionDataLoader extends React.Component {
  render() {
    return (

      <Connect
        query={graphqlOperation(queries.getSignedUrLforUser, { id: this.props.id, timestamp: this.props.timestamp })}
      >
        {({ data, loading }) => {
          if (loading) { return <div>Loading...</div>; }
          console.log(this.props.id, this.props.timestamp);
          console.log(data.getSignedURLforUser);
          return <SessionData signedURL={data.getSignedURLforUser} />;
        }}
      </Connect>
    );
  }
}

class SessionData extends React.Component {



  constructor(props) {
    super(props);
    this.state = { sensorData: null, isLoading: true, leftValues: [10, 10, 10, 10, 10], rightValues: [10, 10, 10, 10, 10], centerX: 0.5, centerY: 0.5 };
    this.handleTimeChange = this.handleTimeChange.bind(this)
  }

  componentWillMount() {
    this.getData();
  }

  handleTimeChange(values) {
    const com = this.getCenterOfMass(values);

    this.setState({ rightValues: values.right, leftValues: values.left, centerX: com.x, centerY: com.y })

  }

  getCenterOfMass(values) {
    const leftX = [0.56, 0.86, 0.57, 0.77, 0.15];
    const rightX = leftX.map(x => (1 - x));
    const y = [0.87, 0.31, 0.25, 0.13, 0.4];
    const h = 600;
    const w = 600;

    //TODO compute COM, maybe need to bind??


    var com = { x: 0.5, y: 0.5 };
    return com;


  }

  render() {
    if (this.state.isLoading) {
      return (
        <Header as='h5'>Loading...</Header>
      );
    }
    else {

      const COMwidth = 600;
      return (


        <Grid columns='equal' >
          <Grid.Column >
            <Segment>
              <MyScatterPlot data={this.state.sensorData} onTimeChange={this.handleTimeChange} />
            </Segment>
          </Grid.Column>
          <Grid.Column stretched >
            <Segment>
              <Insoles leftValues={this.state.leftValues} rightValues={this.state.rightValues} />
            </Segment>
          </Grid.Column>
        </Grid>



        // <div>
        //   <div>
        //     <MyScatterPlot data={this.state.sensorData} onTimeChange={this.handleTimeChange} />
        //   </div>
        //   <div >
        //     <svg height="600" width="0">
        //     </svg>
        //     <div
        //       // style={{
        //       //   position: 'absolute', left: '0%', top: '50%',
        //       //   transform: 'translate(0%, -50%)'
        //       // }}
        //     >
        //       {Insole({ foot: 'left', values: this.state.leftValues })}
        //     </div>
        //     <div
        //       // style={{
        //       //   position: 'absolute', left: '100%', top: '50%',
        //       //   transform: 'translate(-100%, -50%)'
        //       // }}
        //     >
        //       {Insole({ foot: 'right', values: this.state.rightValues })}
        //     </div>

        //     <div
        //       // style={{
        //       //   position: 'absolute', left: '50%', top: '50%',
        //       //   transform: 'translate(-50%, -50%)'
        //       // }}
        //     >
        //       <svg height="600" width={COMwidth}>
        //         <circle cx={this.state.centerX * COMwidth} cy={this.state.centerY * COMwidth} r={20} fill={'black'} />
        //       </svg>
        //     </div>

        //   </div>
        // </div>


      );
    }
  }

  getData() {
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      //console.log(xhr.responseText);

      // TODO: need to error check here

      var rows = xhr.responseText.split('\n');
      rows.shift(); //remove first element (Both,Left,Right)
      rows.shift(); //remove "left"
      var leftSensors = [[], [], [], [], []];
      var rightSensors = [[], [], [], [], []];
      var sensors = [[], [], [], [], []];

      var row;
      var i;
      var point;
      var firstTimeStamp;
      for (row of rows) {
        if (row === 'right') {
          leftSensors = sensors;
          sensors = [[], [], [], [], []];
        } else {
          const v = row.split(',');
          if (firstTimeStamp == null) {
            firstTimeStamp = Number(v[0]);
          }
          for (i = 0; i < 5; i++) {
            point = { x: Number(v[0]) - firstTimeStamp, y: Number(v[i + 1]) };
            sensors[i].push(point);
          }
        }

      }
      rightSensors = sensors;





      this.setState({
        sensorData: { left: leftSensors, right: rightSensors },
        isLoading: false
      });

    });

    // open the request with the verb and the url
    xhr.open('GET', this.props.signedURL);
    // send the request
    xhr.send();
  }

}

class Insoles extends React.Component {


  constructor(props) {
    super(props);
    this.state = {};

  }

  render() {
    const leftX = [0.56, 0.86, 0.57, 0.77, 0.15];
    const rightX = leftX.map(x => (1 - x));
    const leftValues = this.props.leftValues;
    const rightValues = this.props.rightValues;

    const h = 450;
    const w = 200;

    const com = this.computeCOM(leftValues,rightValues);


    // return (
    //   <div width={'100%'} height={'600'} style={{
    //     position: 'absolute', left: '50%', top: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     border: '3px solid #73AD21'
    //   }}>

    //     <div style={{
    //       position: 'absolute', left: '0px', top: '0px',
    //       backgroundImage: `url(${LeftInsole})`,
    //       backgroundSize: '100%',
    //       backgroundRepeat: 'no-repeat',

    //     }} >
    //       <svg height={h} width={w}>
    //         {this.circles(leftX, leftValues)}
    //       </svg>
    //     </div>
    //     <div style={{
    //       position: 'absolute', left: '500px', top: '0px',
    //       backgroundImage: `url(${RightInsole})`,
    //       backgroundSize: '100%',
    //       backgroundRepeat: 'no-repeat',

    //     }} >
    //       <svg height={h} width={w}>
    //         {this.circles(rightX, rightValues)}
    //       </svg>
    //     </div>

    //   </div>
    // );


    const styleA = {
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)',
      height: '450px',
      width: '600px',
      //backgroundColor: 'purple',
      backgroundImage: `url(${LeftInsole}), url(${RightInsole})`,
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: 'auto 100%, auto 100%',
      backgroundPosition: '0% 0%, 100% 0%'

    };
    const styleB = {
      position: 'absolute', left: '0%', top: '0%',
      height: '450px',
      width: '200px',
      backgroundImage: `url(${LeftInsole})`,
      backgroundSize: '200px 450px',
      backgroundRepeat: 'no-repeat'
    };
    const styleC = {
      position: 'absolute', right: '0%', top: '0%',
      height: '450px',
      width: '200px',
      backgroundImage: `url(${RightInsole})`,
      backgroundSize: '200px 450px',
      backgroundRepeat: 'no-repeat'
    };

    return (
      <div style={styleA} >
        <svg height={450} width={600}>
          <svg height={h} width={w}>
            {this.circles(leftX, leftValues)}
          </svg>
          <svg height={h} width={w} x={400}>
            {this.circles(rightX, rightValues)}
          </svg>
          <svg height={450} width={600} >
            <circle cx={com.x} cy={com.y} r={10} fill={'black'} />
          </svg>
        </svg>

      </div>




    );

    



  }


  computeCOM(leftW, rightW){
    const leftX = [0.56, 0.86, 0.57, 0.77, 0.15];
    const rightX = leftX.map(x => (1 - x));
    const y = [0.87, 0.31, 0.25, 0.13, 0.4];
    var xWeighted = 0;
    var yWeighted = 0;
    var weight = 0;


      for(var i = 0; i<5; i++){
        xWeighted = xWeighted + leftW[i]*leftX[i]*200 + rightW[i]*(rightX[i]*200 + 400);
        yWeighted = yWeighted + leftW[i]*y[i]*450 + rightW[i]*y[i]*450;
        weight = weight + leftW[i]+rightW[i];
      }

      const x = xWeighted / weight;
      const Y = yWeighted / weight;

      return {x: x, y: Y}



  }

  // <div style={styleB} >
  //         <svg height={h} width={w}>
  //         {this.circles(leftX, leftValues)}

  //         </svg>
  //       </div>
  //       <div style={styleC} >
  //       <svg height={h} width={w}>
  //       {this.circles(rightX, rightValues)}
  //         </svg>
  //       </div>


  circles(x, r) {
    const y = [0.87, 0.31, 0.25, 0.13, 0.4];
    const w = 200;
    const h = 450;
    const colors = ['#3458eb', '#22a828', '#fcf93a', '#fcae3a', '#fc573a'];
    var array = [];
    for (var i = 0; i < x.length; i++) {
      array.push(<circle cx={w * x[i]} cy={h * y[i]} r={r[i] / 5} fill={colors[i]} />);
    }
    return array;
  }

  sectionStyle(img) {
    return {
      width: "100%",
      height: "100%",
      backgroundImage: `url(${img})`,
      backgroundSize: '200px 600px',
      backgroundRepeat: 'no-repeat',
    }
  };

}

function Insole(props) {

  const leftX = [0.56, 0.86, 0.57, 0.77, 0.15];
  const rightX = leftX.map(x => (1 - x));
  const y = [0.87, 0.31, 0.25, 0.13, 0.4];
  const h = 600;
  const w = 200;
  const colors = ['#3458eb', '#22a828', '#fcf93a', '#fcae3a', '#fc573a'];

  var x;
  var img;
  if (props.foot === 'left') {
    x = leftX;
    img = LeftInsole;
  } else if (props.foot === 'right') {
    x = rightX;
    img = RightInsole;
  }

  function circles() {
    var array = [];
    for (var i = 0; i < x.length; i++) {
      array.push(<circle cx={w * x[i]} cy={h * y[i]} r={props.values[i]} fill={colors[i]} />);
    }
    return array;
  }

  // return (
  //   <div>
  //   <img style={{width: '200px', height: '600px'}} src={RightInsole} />
  //   <svg height={h} width={w}>
  //     {circles()}
  //   </svg>
  //   </div>
  // );

  var sectionStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${img})`,
    backgroundSize: '200px 600px',
    backgroundRepeat: 'no-repeat'
  };

  return (
    <div style={sectionStyle}>
      <svg height={h} width={w}>
        {circles()}
      </svg>
    </div>
  );
}


class App extends Component {
  render() {
    return (
      <Router>
        <Grid padded>
          <Grid.Column>

            <Segment color='green'>
              <Header as='h1'>Pathway Portal</Header>
            </Segment>

            <Route path="/" exact component={UsersListLoader} />

            <Route
              path=""
              render={() => <div><NavLink to='/'>Back to Users list</NavLink></div>}
            />

            <Route
              path="/a/:userId"
              render={props => <SessionsListLoader id={props.match.params.userId} />}
            />

            <Route
              path="/new/:userId/:sessionTS"
              render={props => <SessionDataLoader id={props.match.params.userId} timestamp={props.match.params.sessionTS} />}
            />

          </Grid.Column>
        </Grid>
      </Router>
    );
  }
}

export default withAuthenticator(App, { includeGreetings: true });




class MyScatterPlot extends React.Component {



  constructor() {
    super();
    //this.onChange = this.onChange.bind(this);
    this.handleBrush = this.handleBrush.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    //this.getIndexBounds = this.getIndexBounds.bind(this)
    this.state = { index: 0, zoomDomain: {x: [0, 1000], y: [0, 1000]} };

    this.colors = ['#3458eb', '#22a828', '#fcf93a', '#fcae3a', '#fc573a'];
  }

  // handleZoom(domain) {
  //   this.setState({ selectedDomain: domain });
  // }

  handleBrush(domain) {
    const indexBounds = this.getIndexBounds(domain.x);

    this.setState({ zoomDomain: domain, index: indexBounds.min, indexBounds: indexBounds });

  }

  // onChange(value) {
  //   this.setState({ cursorValue: value });
  //   console.log('a', value);
  //   this.props.onTimeChange(this.getValuesAtTime(value));
  // }

  handleKeyDown(e) {
    var index = this.state.index
    var indexBounds = this.state.indexBounds
    // left arrow
    if (e.keyCode === 37 && index > indexBounds.min) {
      // this.setState(prevState => ({
      //   index: prevState.index - 1
      // }))
      index--;
    } else if (e.keyCode === 39 && index < indexBounds.max) {
      // this.setState(prevState => ({
      //   index: prevState.index + 1
      // }))
      index++;
    }
    const timeStamp = this.props.data.left[0][index].x;
    this.setState({ index: index })
    this.props.onTimeChange(this.getValuesAtTime(timeStamp));

  }

  getIndexBounds(xDomain) {
    const minTime = xDomain[0];
    const maxTime = xDomain[1];
    var minIndex = null;
    var maxIndex = null;

    var i = 0;
    while (i < this.props.data.left[0].length) {
      if (this.props.data.left[0][i].x > minTime && minIndex == null) {
        minIndex = i;
      }
      if (this.props.data.left[0][i].x > maxTime) {
        maxIndex = i - 1;
        break;
      }

      i++;
    }

    const indexes = { min: minIndex, max: maxIndex };
    console.log("indexes: ", indexes)
    return indexes
  }

  getValuesAtTime(time) {
    if (time === null) {
      return { left: [5, 5, 5, 5, 5], right: [5, 5, 5, 5, 5] };
    } else {

      var leftTimeIndex, rightTimeIndex;
      var i = 0;
      while (i < this.props.data.right[0].length) {
        if (time < this.props.data.right[0][i].x) {
          rightTimeIndex = i;
          break;
        }

        i++;
      }
      var rightValues = [];
      for (i = 0; i < 5; i++) {
        rightValues.push(this.props.data.right[i][rightTimeIndex].y);
      }

      //left
      var i = 0;
      while (i < this.props.data.left[0].length) {
        if (time < this.props.data.left[0][i].x) {
          leftTimeIndex = i;
          break;
        }

        i++;
      }
      var leftValues = [];
      for (i = 0; i < 5; i++) {
        leftValues.push(this.props.data.left[i][leftTimeIndex].y);
      }
      console.log('calc', leftValues, rightValues);
      return { left: leftValues, right: rightValues };
    }
  }

  filterData(data){
    const domain = this.state.zoomDomain;

    return data.filter(
      // is d "between" the ends of the visible x-domain?
      (d) => (d.x >= domain.x[0] && d.x <= domain.x[1]));

  }

  drawSensorLines(data){
    var array = [];
    for (var i = 0; i < data.length; i++) {
      array.push(<VictoryLine
        style={{
          data: { stroke: this.colors[i] }
        }}
        data={this.filterData(data[i])}
      />);
    }
    return array;

  }

  render() {
    const timeStamp = this.props.data.left[0][this.state.index].x;
    //const yMax = (this.state.zoomDomain.y[1] == null) ? 0 : this.state.zoomDomain.y[1]
    const cursorPoints = [
      { x: timeStamp, y: 0 },
      { x: timeStamp, y: 1000 }
    ]

    //const VictoryNewContainer = createContainer("zoom", "cursor");
    //<div style={{transform: 'scale(1, -1)'}}>
    return (
      <Container>
        <Input onKeyDown={this.handleKeyDown} />

        
        <Header as='h5'>Left then Right</Header>
        

        
        <VictoryChart width={600} height={350}
          containerComponent=
          {
            <VictoryZoomContainer responsive={false} disable={true}
              allowZoom={false}
              allowPan={false}
              zoomDimension="x"
              zoomDomain={this.state.zoomDomain}
            />
          }
        // {
        //   <VictoryNewContainer responsive={false} 
        //   allowZoom={false}
        //   allowPan={false}
        //     zoomDimension="x"
        //     cursorDimension="x"
        //     zoomDomain={this.state.zoomDomain}
        //     //onZoomDomainChange={this.handleZoom.bind(this)}
        //     onCursorChange={this.onChange}
        //   />
        // }
        >
          {/* <VictoryLine
            style={{
              data: { stroke: "#3458eb" }
            }}
            data={this.props.data.left[0]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#22a828" }
            }}
            data={this.props.data.left[1]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcf93a" }
            }}
            data={this.props.data.left[2]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcae3a" }
            }}
            data={this.props.data.left[3]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fc573a" }
            }}
            data={this.props.data.left[4]}
          /> */}
          {this.drawSensorLines(this.props.data.left)}
          <VictoryLine
            style={{
              data: { stroke: "black" }
            }}
            data={cursorPoints}
          />

        </VictoryChart>
        

        <VictoryChart width={600} height={350}
          containerComponent={
            <VictoryZoomContainer responsive={false} disable={true}
              allowZoom={false}
              allowPan={false}
              zoomDimension="x"
              zoomDomain={this.state.zoomDomain}
            />
          }
        >
          {/* <VictoryLine
            style={{
              data: { stroke: "#3458eb" }
            }}
            data={this.props.data.right[0]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#22a828" }
            }}
            data={this.props.data.right[1]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcf93a" }
            }}
            data={this.props.data.right[2]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcae3a" }
            }}
            data={this.props.data.right[3]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fc573a" }
            }}
            data={this.props.data.right[4]}
          /> */}
          {this.drawSensorLines(this.props.data.right)}
          <VictoryLine
            style={{
              data: { stroke: "black" }
            }}
            data={cursorPoints}
          />

        </VictoryChart>

        <VictoryChart
          padding={{ top: 0, left: 50, right: 50, bottom: 0 }}
          width={600} height={90}
          domain={{x:[0,100000],y:[0,1000]}}
          containerComponent=
          {
            <VictoryBrushContainer responsive={false}
              brushDimension="x"
              //brushDomain={this.state.selectedDomain}
              onBrushDomainChange={this.handleBrush}
            />
          }
        >

          {/* <VictoryLine
            style={{
              data: { stroke: "#3458eb" }
            }}
            data={this.props.data.left[0]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#22a828" }
            }}
            data={this.props.data.left[1]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcf93a" }
            }}
            data={this.props.data.left[2]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcae3a" }
            }}
            data={this.props.data.left[3]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fc573a" }
            }}
            data={this.props.data.left[4]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#3458eb" }
            }}
            data={this.props.data.right[0]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#22a828" }
            }}
            data={this.props.data.right[1]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcf93a" }
            }}
            data={this.props.data.right[2]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fcae3a" }
            }}
            data={this.props.data.right[3]}
          />
          <VictoryLine
            style={{
              data: { stroke: "#fc573a" }
            }}
            data={this.props.data.right[4]}
          /> */}
        </VictoryChart>
      </Container>
    );
  }
}




// Simple query
// const GetMyUsers = await API.graphql(graphqlOperation(queries.getMyUsers));
// console.log(GetMyUsers);


// class NewAlbum extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       albumName: ''
//       };
//     }

//   handleChange = (event) => {
//     let change = {};
//     change[event.target.name] = event.target.value;
//     this.setState(change);
//   }

//   handleSubmit = async (event) => {
//     event.preventDefault();
//     const NewAlbum = `mutation NewAlbum($name: String!) {
//       createAlbum(input: {name: $name}) {
//         id
//         name
//       }
//     }`;

//     const result = await API.graphql(graphqlOperation(NewAlbum, { name: this.state.albumName }));
//     console.info(`Created album with id ${result.data.createAlbum.id}`);
//     this.setState({ albumName: '' })
//   }

//   render() {
//     return (
//       <Segment>
//         <Header as='h3'>Add a new album</Header>
//           <Input
//           type='text'
//           placeholder='New Album Name'
//           icon='plus'
//           iconPosition='left'
//           action={{ content: 'Create', onClick: this.handleSubmit }}
//           name='albumName'
//           value={this.state.albumName}
//           onChange={this.handleChange}
//           />
//         </Segment>
//       )
//     }
// }



// class AlbumDetailsLoader extends React.Component {
//   render() {
//     return (
//       <Connect query={graphqlOperation(queries.getUserSessions, { id: this.props.sub })}>
//         {({ data, loading }) => {
//           if (loading) { return <div>Loading...</div>; }
//           if (!data.getAlbum) return;

//           return <AlbumDetails user={data.getAlbum} />;
//         }}
//       </Connect>
//     );
//   }
// }


// class AlbumDetails extends Component {
//   render() {
//     return (
//       <Segment>
//         <Header as='h3'>{this.props.album.name}</Header>
//         <p>TODO: Allow photo uploads</p>
//         <p>TODO: Show photos for this album</p>
//       </Segment>
//     )
//   }
// }