// eslint-disable
// this is an auto generated file. This will be overwritten

export const getSession = `query GetSession($id: ID!) {
  getSession(id: $id) {
    obj
    timeStamp
    sessionLength
    exerciseType
    exerciseID
    gridRounds
    repititions
    steps
    cadence
    distance
    insolesUsed
  }
}
`;
export const listSessions = `query ListSessions(
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    sessions {
      obj
      timeStamp
      sessionLength
      exerciseType
      exerciseID
      gridRounds
      repititions
      steps
      cadence
      distance
      insolesUsed
    }
    nextToken
  }
}
`;
export const listExercise = `query ListExercise(
  $filter: ModelExerciseFilterInput
  $limit: Int
  $nextToken: String
) {
  listExercise(filter: $filter, limit: $limit, nextToken: $nextToken) {
    exercises {
      obj
      name
      exerciseType
      insolesUsed
      gridShowing
      lastUsed
    }
    nextToken
  }
}
`;
export const getMyUsers = `query GetMyUsers {
  getMyUsers {
    email
    sub
  }
}
`;
export const getUserSessions = `query GetUserSessions($id: String) {
  getUserSessions(id: $id) {
    obj
    timeStamp
    sessionLength
    exerciseType
    exerciseID
    gridRounds
    repititions
    steps
    cadence
    distance
    insolesUsed
  }
}
`;
export const getMySessions = `query GetMySessions {
  getMySessions {
    obj
    timeStamp
    sessionLength
    exerciseType
    exerciseID
    gridRounds
    repititions
    steps
    cadence
    distance
    insolesUsed
  }
}
`;
export const getMyExercises = `query GetMyExercises {
  getMyExercises {
    obj
    name
    exerciseType
    targets {
      x
      y
      width
      height
      timer
      targetType
    }
    balanceGrid {
      x
      y
      width
      height
      tileLength
    }
    leftConfig {
      thresholds
      activeSensors
      logic
      total
      feedback
    }
    rightConfig {
      thresholds
      activeSensors
      logic
      total
      feedback
    }
    settings {
      weightType
      circles
      numbers
      comPoint
      tracing
      scrolling
    }
    insolesUsed
    gridShowing
    lastUsed
  }
}
`;
export const getUsers = `query GetUsers($beginsWith: String) {
  getUsers(beginsWith: $beginsWith)
}
`;
export const getMyPermissions = `query GetMyPermissions {
  getMyPermissions {
    email
    sub
  }
}
`;
export const getMySessionsSince = `query GetMySessionsSince($time: String) {
  getMySessionsSince(time: $time) {
    obj
    timeStamp
    sessionLength
    exerciseType
    exerciseID
    gridRounds
    repititions
    steps
    cadence
    distance
    insolesUsed
  }
}
`;
export const getSchedule = `query GetSchedule {
  getSchedule
}
`;
export const getScheduleFor = `query GetScheduleFor($date: String) {
  getScheduleFor(date: $date)
}
`;
export const getComplianceForUser = `query GetComplianceForUser($id: String) {
  getComplianceForUser(id: $id)
}
`;
export const getSignedUrLforUser = `query GetSignedUrLforUser($id: String, $timestamp: String) {
  getSignedURLforUser(id: $id, timestamp: $timestamp)
}
`;
export const getUserExercises = `query GetUserExercises($id: String) {
  getUserExercises(id: $id) {
    obj
    name
    exerciseType
    targets {
      x
      y
      width
      height
      timer
      targetType
    }
    balanceGrid {
      x
      y
      width
      height
      tileLength
    }
    leftConfig {
      thresholds
      activeSensors
      logic
      total
      feedback
    }
    rightConfig {
      thresholds
      activeSensors
      logic
      total
      feedback
    }
    settings {
      weightType
      circles
      numbers
      comPoint
      tracing
      scrolling
    }
    insolesUsed
    gridShowing
    lastUsed
  }
}
`;
