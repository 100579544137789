// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b732618c-7387-486f-902b-48b22d6822e4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LVcmkLP4X",
    "aws_user_pools_web_client_id": "4q6fm86mm59mu4bi62gfc1mgn8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://jbj5t7cpkrfetkh4olc5tq7gqm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
